<template>
  <router-view></router-view>
</template>

<script>
  // TODO: make it functional component
  export default {
    layout(context) {
      return context.store.state.site.template
    },
    async validate(context) {
      let layout_components = await import('@/layouts/' + context.store.state.site.template + '/components.js');
      let component_name = context.route.matched.find(v => v.props.default).props.default.routingComponentName;
      if (!component_name || !layout_components.default || !layout_components.default[component_name]) return false;
      return true;
    },
  }
</script>
